import { useState, useCallback, useMemo } from "react";
import { FixedSizeList as List } from "react-window";
import debounce from "lodash.debounce";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";

const SymbolSelector = ({
  selectedSymbol,
  onSelectSymbol,
  searchTerm,
  onSearchChange,
  symbolsList,
}) => {
  const MAX_DISPLAY_ITEMS = 50; // Adjust as needed for speed
  const [showOptions, setShowOptions] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

  // Debounced handler to limit search triggering
  const handleSearchChangeDebounced = useCallback(
    debounce((value) => {
      onSearchChange(value);
    }, 300),
    [onSearchChange]
  );

  // Update search term immediately for input responsiveness
  const handleLocalSearchChange = (event) => {
    const value = event.target.value;
    setLocalSearchTerm(value);
    handleSearchChangeDebounced(value);
  };

  // Memoized filtered symbols list
  const displayedSymbols = useMemo(() => {
    if (!localSearchTerm) return symbolsList.slice(0, MAX_DISPLAY_ITEMS);

    const searchTermLower = localSearchTerm.toLowerCase();

    // First, attempt to filter by name
    let filteredSymbols = symbolsList.filter((symbol) =>
      symbol.instrument.toLowerCase().includes(searchTermLower)
    );

    // If no matches by name, filter by instrument instead
    if (filteredSymbols.length === 0) {
      filteredSymbols = symbolsList.filter((symbol) =>
        symbol.name.toLowerCase().includes(searchTermLower)
      );
    }

    // Return the top items up to MAX_DISPLAY_ITEMS
    return filteredSymbols.slice(0, MAX_DISPLAY_ITEMS);
  }, [localSearchTerm, symbolsList]);

  return (
    <Box position="relative" display="inline-block" width="250px">
      <InputGroup>
        <Input
          value={selectedSymbol}
          onClick={() => setShowOptions((prev) => !prev)}
          placeholder="Select Scripts"
          variant="filled"
          bg="gray.100"
          _focus={{ bg: "white", borderColor: "gray.300" }}
          readOnly
          cursor="pointer"
        />
        <InputRightElement width="2.5rem">
          <IconButton
            aria-label="Dropdown icon"
            icon={<MdArrowDropDown />}
            variant="ghost"
            onClick={() => setShowOptions((prev) => !prev)}
          />
        </InputRightElement>
      </InputGroup>

      {showOptions && (
        <Box
          position="absolute"
          top="100%"
          left={0}
          right={0}
          bg="white"
          boxShadow="lg"
          zIndex={10}
          maxHeight="250px"
          overflow="hidden"
          borderRadius="md"
          mt={1}
          p={2}
        >
          <Input
            value={localSearchTerm}
            onChange={handleLocalSearchChange}
            placeholder="Search Scripts"
            bg="gray.50"
            mb={2}
            _focus={{ bg: "white", borderColor: "blue.500" }}
          />

          <List
            height={250}
            itemCount={displayedSymbols.length}
            itemSize={50}
            width="100%"
          >
            {({ index, style }) => (
              <Tooltip label={displayedSymbols[index].name} hasArrow>
                <Box
                  key={displayedSymbols[index].instrument}
                  onClick={() => {
                    onSelectSymbol(displayedSymbols[index]);
                    setShowOptions(false); // Close dropdown on select
                  }}
                  style={style}
                  cursor="pointer"
                  _hover={{ background: "gray.100" }}
                  px={4}
                  py={2}
                  borderRadius="md"
                  display="flex"
                  alignItems="center"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {displayedSymbols[index].name}
                </Box>
              </Tooltip>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SymbolSelector;
