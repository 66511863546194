import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useDisclosure,
  VStack,
  Flex,
  HStack,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import { FaAngleDown, FaAngleUp, FaSearch } from "react-icons/fa";
import PositionedMenu from "../PositionedMenu/PositionedMenu";
import AddClientModal from "../AddClientModal/AddClientModal";
import Cookies from "cookies-js";
import { useDispatch } from "react-redux";
import { dealerClientMapping } from "../../../Redux/raDealerReducer/action";

function Row({ dealer, clients, onAddClient }) {
  const { isOpen, onToggle } = useDisclosure();
  const [currentClientPage, setCurrentClientPage] = useState(1);
  const [searchClient, setSearchClient] = useState("");

  const dealerClients = clients.filter(
    (client) => client.dealerID === dealer.ID
  );
  const clientPerPage = 10;

  const filteredClients = dealerClients.filter(
    (dealer) =>
      dealer?.centrumID?.toLowerCase().includes(searchClient.toLowerCase()) ||
      dealer?.username?.toLowerCase().includes(searchClient.toLowerCase()) ||
      dealer?.number?.includes(searchClient)
  );

  const totalClientPages = Math.ceil(filteredClients.length / clientPerPage);

  const startIndex = (currentClientPage - 1) * clientPerPage;
  const currentClients = filteredClients.slice(
    startIndex,
    startIndex + clientPerPage
  );

  const handleClientPageChange = (page) => {
    if (page >= 1 && page <= totalClientPages) {
      setCurrentClientPage(page);
    }
  };

  return (
    <>
      <Tr bg={"#f5f5f5"} borderBottom="2px solid #eaeaea">
        <Td>
          <IconButton
            aria-label="Expand row"
            icon={isOpen ? <FaAngleUp /> : <FaAngleDown />}
            size="sm" // Make the button slightly larger
            onClick={onToggle}
            variant="solid" // Use a solid variant for more emphasis
          />
        </Td>
        <Td fontWeight="bold" color="blue.700">
          {dealer.centrumID}
        </Td>
        <Td fontWeight="bold" color="blue.700">
          {dealer.username}
        </Td>
        <Td>{dealer.number}</Td>
        <Td>
          <PositionedMenu onAddClient={onAddClient} dealerId={dealer.ID} />
        </Td>
      </Tr>
      {isOpen && (
        <Tr>
          <Td colSpan={5} py={4}>
            <VStack align="start" spacing={4} px={4}>
              <Box
                display="flex"
                width="46%"
                alignItems="center"
                justifyContent="space-between"
                p={2}
              >
                <Text fontWeight="bold" fontSize="lg" color="blue.700">
                  Client List
                </Text>
                <InputGroup size="sm" maxWidth="200px">
                  <InputLeftElement pointerEvents="none">
                    <FaSearch color="gray.500" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search Client"
                    variant="flushed"
                    value={searchClient}
                    onChange={(e) => setSearchClient(e.target.value)}
                    borderRadius="md"
                    focusBorderColor="blue.500"
                    bg="gray.50"
                  />
                </InputGroup>
              </Box>

              <Box
                borderWidth="1px"
                borderRadius="md"
                overflow="hidden"
                bg="white"
                shadow="md"
              >
                <Table size="sm" variant="striped" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th
                        color="blue.700"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Centrum ID
                      </Th>
                      <Th
                        color="blue.700"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Client Name
                      </Th>
                      <Th
                        color="blue.700"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Mobile Number
                      </Th>
                      <Th
                        color="blue.700"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Email ID
                      </Th>
                    </Tr>
                  </Thead>
                  {currentClients.length > 0 ? (
                    <Tbody>
                      {currentClients.map((client) => (
                        <Tr
                          key={client.ID}
                          bg={"#f5f5f5"}
                          borderBottomWidth="2px"
                          borderBottomColor="gray.500"
                        >
                          <Td>{client.centrumID}</Td>
                          <Td>{client.username}</Td>
                          <Td>{client.number}</Td>
                          <Td>{client.email}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  ) : (
                    <Tr>
                      <Td colSpan={4} textAlign="center" color="gray.500">
                        No clients available.
                      </Td>
                    </Tr>
                  )}
                </Table>

                {totalClientPages>1?(
           <Flex justify="center" align="center" py={2} gap={4}>
           <Button
             size="sm"
             onClick={() => handleClientPageChange(currentClientPage - 1)}
             isDisabled={currentClientPage === 1}
             variant="ghost"
             colorScheme="blue"
             borderRadius="full"
             _hover={{ bg: "blue.100" }}
             _disabled={{ opacity: 0.5, cursor: "not-allowed" }}
           >
             <ChevronLeftIcon boxSize={5} color={currentClientPage === 1 ? "gray.400" : "blue.600"} />
           </Button>
         
          {Array.from({length:totalClientPages}, (_,index)=>(
           <Button
           key={index}
           size={'sm'}
           borderRadius={'full'}
           mx={1}
           onClick={()=>handleClientPageChange(index+1)}
           colorScheme={currentClientPage ===index+1? 'blue':'gray'}
           
           >{index+1}</Button>
          ))}
         
           <Button
             size="sm"
             isDisabled={currentClientPage === totalClientPages}
             onClick={() => handleClientPageChange(currentClientPage + 1)}
             variant="ghost"
             colorScheme="blue"
             borderRadius="full"
             _hover={{ bg: "blue.100" }}
             _disabled={{ opacity: 0.5, cursor: "not-allowed" }}
           >
             <ChevronRightIcon
               boxSize={5}
               color={
                 startIndex + clientPerPage >= filteredClients.length
                   ? "gray.400"
                   : "blue.600"
               }
             />
           </Button>
         </Flex>
        ):""}
              </Box>
            </VStack>
          </Td>
        </Tr>
      )}
    </>
  );
}

Row.propTypes = {
  dealer: PropTypes.shape({
    centrumID: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    ID: PropTypes.string.isRequired,
  }).isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      emailId: PropTypes.string.isRequired,
      dealerID: PropTypes.string.isRequired,
      ID: PropTypes.string.isRequired,
    })
  ).isRequired,
  onAddClient: PropTypes.func.isRequired,
};

export default function TableDealerList({
  clients,
  dealers,
  setToggle,
  toggle,
}) {
  const [currentDealerPage, setCurrentDealerPage] = useState(1);
  const [selectedDealerId, setSelectedDealerId] = useState(null);
  const [searchDealer, setSearchDealer] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  let token = Cookies.get("login_token_admin");

  const dispatch = useDispatch();
  const toast = useToast(); // Initialize toast
  const dealersPerPage = 10;
 
  const handleAddClient = (dealerId) => {
    setSelectedDealerId(dealerId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedDealerId(null);
  };

  const handleClientAddition = (clientId) => {
  

    const data = {
      clientID: clientId,
      dealerID: selectedDealerId,
    };

    dispatch(dealerClientMapping(data, token))
      .then((res) => {
    
        if (res.status === "success") {
          toast({
            title: "Client Added",
            description: `Client ${data.clientID} successfully added to dealer ${data.dealerID}.`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          setToggle(!toggle);
          handleCloseModal();
        }
      })
      .catch((error) => {
        console.error(error, "Error while adding client.");
        toast({
          title: "Error",
          description:
            "An error occurred while adding the client. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        handleCloseModal();
      });
  };

  const filteredDealers = dealers.filter(
    (dealer) =>
      dealer?.centrumID?.toLowerCase().includes(searchDealer.toLowerCase()) ||
      dealer?.username?.toLowerCase().includes(searchDealer.toLowerCase()) ||
      dealer?.number?.includes(searchDealer)
  );

  const totalDealerPages = Math.ceil(filteredDealers.length / dealersPerPage);

  const startIndex = (currentDealerPage - 1) * dealersPerPage;
  const currentDealers = filteredDealers.slice(
    startIndex,
    startIndex + dealersPerPage
  );

  const handleDealerPageChange = (page) => {
    if (page >= 1 && page <= totalDealerPages) {
      setCurrentDealerPage(page);
    }
  };

  return (
    <VStack align="center" colorScheme="gray.100">
      <Box
        w="full"
        maxW="6xl"
        // p={6}
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="xl"
        bgGradient="linear(to-br, white, gray.50)"
      >
        <Text
          fontSize="2xl"
          fontWeight="bold"
          textAlign="center"
          color="blue.700"
        >
          Dealer List
        </Text>
        <Box overflowX="auto">
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th></Th>
                <Th
                  color="blue.700"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Centrum ID
                </Th>
                <Th
                  color="blue.700"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Dealer Name
                </Th>
                <Th
                  color="blue.700"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Mobile Number
                </Th>
                <Th
                  color="blue.700"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  <InputGroup size="sm" mt={2}>
                    <InputLeftElement pointerEvents="none">
                      <FaSearch color="gray.500" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search Dealer"
                      value={searchDealer}
                      variant="flushed"
                      onChange={(e) => setSearchDealer(e.target.value)}
                      borderRadius="md"
                      focusBorderColor="blue.500"
                      bg="gray.50"
                    />
                  </InputGroup>
                </Th>
              </Tr>
            </Thead>
            {currentDealers.length > 0 ? (
              <Tbody>
                {currentDealers.map((dealer) => (
                  <Row
                    key={dealer.ID}
                    dealer={dealer}
                    clients={clients}
                    onAddClient={handleAddClient}
                  />
                ))}
              </Tbody>
            ) : (
              <Tr>
                <Td colSpan={4} textAlign="center" color="gray.500">
                  No Dealer Available.
                </Td>
              </Tr>
            )}
          </Table>
        </Box>
        {totalDealerPages > 1 ? (
          <Flex justify="center" align="center" py={2} gap={4}>
            <Button
              size="sm"
              onClick={() => handleDealerPageChange(currentDealerPage - 1)}
              isDisabled={currentDealerPage === 1}
              variant="ghost"
              colorScheme="blue"
              borderRadius="full"
              _hover={{ bg: "blue.100" }}
              _disabled={{ opacity: 0.5, cursor: "not-allowed" }}
            >
              <ChevronLeftIcon
                boxSize={5}
                color={currentDealerPage === 1 ? "gray.400" : "blue.600"}
              />
            </Button>

            {Array.from({ length: totalDealerPages }, (_, index) => (
              <Button
                key={index}
                size={"sm"}
                borderRadius={"full"}
                mx={1}
                onClick={() => handleDealerPageChange(index + 1)}
                colorScheme={currentDealerPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}

            <Button
              size="sm"
              isDisabled={currentDealerPage === totalDealerPages}
              onClick={() => handleDealerPageChange(currentDealerPage + 1)}
              variant="ghost"
              colorScheme="blue"
              borderRadius="full"
              _hover={{ bg: "blue.100" }}
              _disabled={{ opacity: 0.5, cursor: "not-allowed" }}
            >
              <ChevronRightIcon
                boxSize={5}
                color={
                  startIndex + dealersPerPage >= filteredDealers.length
                    ? "gray.400"
                    : "blue.600"
                }
              />
            </Button>
          </Flex>
        ) : (
          ""
        )}
      </Box>

      <AddClientModal
        open={isModalOpen}
        onClose={handleCloseModal}
        clients={clients}
        onAddClient={handleClientAddition}
      />
    </VStack>
  );
}

TableDealerList.propTypes = {
  dealers: PropTypes.arrayOf(
    PropTypes.shape({
      centrumID: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      ID: PropTypes.string.isRequired,
    })
  ).isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      emailId: PropTypes.string.isRequired,
      dealerID: PropTypes.string.isRequired,
      ID: PropTypes.string.isRequired,
    })
  ).isRequired,
};
