import axios from "axios";
import { BASKET_REQUEST, GET_BASKET_SUCCESS } from "../actionTypes";


let URL=process.env.REACT_APP_NewURL
// let V3_URL = process.env.REACT_APP_VTHREE
let V3_URL = process.env.REACT_APP_VTHREE_AWS

export const getSuccessAction = (payload) => {
  return { type: GET_BASKET_SUCCESS, payload };
};

export const fetchBasket = (token) => (dispatch) => {
  dispatch({ type: BASKET_REQUEST });
  return axios.get(`${V3_URL}centrum-galaxc/user/v3/all/basket-list?size=100&page=1`, {
    headers: {
      Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
    },
  
  });
};


// Action to fetch basket info
export const fetchBasketInfo = (token) => {
  return axios.get(`${V3_URL}centrum-galaxc/user/v3/all/basket-info`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postBasketData = (dataToSend, token) => async (dispatch) => {

  try {
    dispatch({ type: BASKET_REQUEST });
    const response = await axios.post(`${V3_URL}centrum-galaxc/user/v3/team/create-basket/`, dataToSend, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
      },
    });

if(response.data!==undefined){

  return response.data; // Return the response data if needed elsewhere
}
  } catch (error) {
    console.log("Error creating basket:", error);
    // return error
    // throw new Error(error); // Optionally re-throw the error for further handling
  }
};

// Corrected fetchSingleBasketData function
export const fetchSingleBasketData = (id, token) => async (dispatch) => {
  try {
    const response = await axios.get(`${V3_URL}centrum-galaxc/user/v3/all/basket-list?basketID=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
      }
    });
    
    
    return response.data; // Return the data for further processing
  } catch (error) {
    console.log(error, "fetchSingleBasketData error");
  }
};



// Corrected fetchSingleBasketData function
export const fetchLatestBasketList = (id, token) => async (dispatch) => {
  try {
    const response = await axios.get(`${V3_URL}centrum-galaxc/user/v3/all/latest-basket-list?basketID=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
      }
    });
    
    
    return response.data; // Return the data for further processing
  } catch (error) {
    console.log(error, "fetchSingleBasketData error");
  }
};


// Edit basket
export const editBasketData =
  (basket_id, token,data) => async (dispatch) => {

    try {
      const response = await axios.put(
        `${V3_URL}centrum-galaxc/user/v3/team/edit-basket?basketID=${basket_id}`,
        data, 
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
          } 
        }
      );
      if(response.data.success=="success"){
        fetchSingleBasketData(basket_id,token)
      }

     
      return response.data; // Return the response data if needed elsewhere
    } catch (error) {
      console.log("Error updating basket:", error);
      return error.message
      throw new Error(error); // Optionally re-throw the error for further handling
    }
  };


  export const makeBasketDecision =
  (decision, basket_id, token, researchHeadMessage) => async (dispatch) => {
   
    try {
      const response = await axios.post(
        `${V3_URL}centrum-galaxc/user/v3/team/basket-decision/?basketID=${basket_id}&reason=${researchHeadMessage}&decision=${decision}`,  // Fixed parameter order
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token for authentication
          },
        }
      );

   
      return response.data; // Return the response data if needed elsewhere
    } catch (error) {
      console.error("Error updating basket:", error.response ? error.response.data : error.message);  // Detailed error logging
      throw new Error(error); // Optionally re-throw the error for further handling
    }
  };


