import axios from "axios";
import {
  GET_RESEARCH_ANALYSTS_REQUEST,
  GET_RESEARCH_ANALYSTS_SUCCESS,
  GET_RESEARCH_ANALYSTS_FAILURE,
  GET_DEALERS_REQUEST,
  GET_DEALERS_SUCCESS,
  GET_DEALERS_FAILURE,
  POST_RESEARCH_ANALYST_REQUEST,
  POST_RESEARCH_ANALYST_SUCCESS,
  POST_RESEARCH_ANALYST_FAILURE,
  POST_DEALER_REQUEST,
  POST_DEALER_SUCCESS,
  POST_DEALER_FAILURE
} from "../actionTypes";

 
let apiUrl=process.env.REACT_APP_NewURL
// let V3_URL = process.env.REACT_APP_VTHREE
let V3_URL = process.env.REACT_APP_VTHREE_AWS

// GET researchAnalysts
export const getResearchAnalysts = (role,token) => async (dispatch) => {
  dispatch({ type: GET_RESEARCH_ANALYSTS_REQUEST });
  try {
    const response = await axios.get(`${V3_URL}centrum-galaxc/user/v3/team/manager-list?managerRole=${role}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
      },
    });

   
  if(response.data.status=="success"){
   
let filterData=response.data.data.managerList.filter((ele)=>ele.role=='ra')

    dispatch({ type: GET_RESEARCH_ANALYSTS_SUCCESS, payload: filterData });
  }
  } catch (error) {
    console.log(error,"getResearchAnalysts Error")
    dispatch({ type: GET_RESEARCH_ANALYSTS_FAILURE, payload: error.message });
  }
};

// GET dealers
export const getDealers = (role,token) => async (dispatch) => {
  dispatch({ type: GET_DEALERS_REQUEST });
  try {
    const response = await axios.get(`${V3_URL}centrum-galaxc/user/v3/team/manager-list?managerRole=${role}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
      },
    });
 
    if(response.data.status==='success'){

      dispatch({ type: GET_DEALERS_SUCCESS, payload: response.data.data.managerList });
    }
  } catch (error) {
    console.log(error,"error getDealers")
    dispatch({ type: GET_DEALERS_FAILURE, payload: error.message });
  }
};

// POST researchAnalyst
export const postResearchAnalyst = (sendData, token) => async (dispatch) => {
  dispatch({ type: POST_RESEARCH_ANALYST_REQUEST });
 
  try {
    const response = await axios.post(`${V3_URL}centrum-galaxc/user/v3/team/create-manager`, sendData, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
      },
    });
    if(response.data.status=="success"){
      getDealers(token)
      getResearchAnalysts(token)
    }
  
    return response.data;
    // dispatch({ type: POST_RESEARCH_ANALYST_SUCCESS, payload: response.data });
  } catch (error) {
    console.log(error,"postDealer error")
    dispatch({ type: POST_RESEARCH_ANALYST_FAILURE, payload: error.message });
  }
};

// POST postDealer
export const postDealer = (sendData, token) => async (dispatch) => {
  dispatch({ type: POST_RESEARCH_ANALYST_REQUEST });
  
  try {
    const response = await axios.post(`${V3_URL}centrum-galaxc/user/v3/team/create-manager`, sendData, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
      },
    });
    if(response.data.status=="success"){
      getDealers(token)
      getResearchAnalysts(token)
    }
  
    return response.data;
    // dispatch({ type: POST_RESEARCH_ANALYST_SUCCESS, payload: response.data });
  } catch (error) {
    console.log(error,"postDealer error")
    dispatch({ type: POST_RESEARCH_ANALYST_FAILURE, payload: error.message });
  }
};

export const updateStatus = (id,status, token) => async (dispatch) => {
  dispatch({ type: POST_RESEARCH_ANALYST_REQUEST });
  try {
    const response = await axios.post(`${apiUrl}/permitions/dealer-status?dealer_id=${id}&dealer_status=${status}`,  {},{
      headers: { "Access-Token": token },
    });
    if(response.data.status=="success"){
      getDealers(token)
      getResearchAnalysts(token)
    }
    return response.data;
    // dispatch({ type: POST_RESEARCH_ANALYST_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: POST_RESEARCH_ANALYST_FAILURE, payload: error.message });
  }
};




export const updateStatusResearchAnalyst = (id,status, token) => async (dispatch) => {
  dispatch({ type: POST_RESEARCH_ANALYST_REQUEST });
  try {
    const response = await axios.post(`${apiUrl}/permitions/manager-status?manager_id=${id}&manager_status=${status}`,  {},{
      headers: { "Access-Token": token },
    });
    if(response.data.status=="success"){
      getDealers(token)
      getResearchAnalysts(token)
    }
    return response.data;
    // dispatch({ type: POST_RESEARCH_ANALYST_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: POST_RESEARCH_ANALYST_FAILURE, payload: error.message });
  }
};
// change Status of Dealer 

export const dealerClientMapping = (data, token) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${V3_URL}centrum-galaxc/user/v3/team/dealer-client-mapping`,
      {}, // Empty request body
      {
        headers: {
          Authorization: `Bearer ${token}`, // Pass Bearer token for authentication
        },
        params: {
          clientID: data.clientID,
          dealerID: data.dealerID,
        },
      }
    );

 

    return response.data; // Return data for further use
  } catch (error) {
    console.error("Error in dealerClientMapping:", error);
   

    // Throw the error to handle it in the calling function
    throw error;
  }
};
