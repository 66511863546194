import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Heading,
  Th,
  Td,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Badge,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
// import Navbar from "../../Cenrum-ResearchAnalyst-Head/Components/Navbar";
import Navbar from "../../Components/RasearchAnalyst-Head/Navbar";
import Cookies from "js-cookie";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLatestBasketList,
  fetchSingleBasketData,
  makeBasketDecision,
} from "../../Redux/basketReducer/action";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { fetchSymbols } from "../../Redux/symbolReducer/action";

export default function RaHeadBasketDetails() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [totalFundRequired, setTotalFundRequired] = useState(0);
  const [editableBaskets, setEditableBaskets] = useState({});

  const [researchHeadMessage, setresearchHeadMessage] = useState("");
  const [updateToggle, setUpdateToggle] = useState(false);
  const [statusUpdated, setStatusUpdated] = useState(true);
  const [rejected, setRejected] = useState(false);
  const [isApprovalPending, setIsApprovalPending] = useState(false);
  const [isLoading,setIsLoading] =useState(false)

  const [latestInstruments, setLatestInstruments] = useState([]);
  const [primaryInstruments, setPrimaryInstruments] = useState([]);
  const [historyInstruments, setHistoryInstruments] = useState([]);
  const [pendingInstruments, setPendingInstruments] = useState([]);
  const [approvedReason, setApprovedReason] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");

  const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
  const [currentPrimaryPage, setCurrentPrimaryPage] = useState(1);
  const [latestInstrumentPage, setLatestInstrumentPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  let { id } = useParams();
  let token = Cookies.get("login_token_rh");

  const Symbols = useSelector((store) => store.symbolsReducer.symbols);

  useEffect(() => {
    dispatch(fetchSingleBasketData(id, token))
      .then((res) => {
        // Token expiration check
        if (res.detail === "Token has expired") {
          Cookies.set("login_token_rh", "");
          Cookies.set("username_rh", "");
          navigate("/rahead");
        }

        // Permission error handling
        if (res.message === "You do not have permission to access the data") {
          Cookies.set("login_token_rh", "");
          Cookies.set("username_rh", "");
          // navigate('/rahead');
        }

        // Basket data
        const basket = res.data.basketList[0]; // Assuming the basket list is returned in the first index
        setData(basket);

        let isApproved = false;
        let isRejected = false;
        let isPending = false;
        let isApprovalPending = false; // Updated name

        // Variables to hold the categorized instruments
        let latestInstruments = [];
        let primaryInstruments = [];
        let historyInstruments = [];
        let pendingInstruments = [];

        let rejectedReason = "";
        let approvedReason = "";

        // Check instruments of type "Primary" and raHeadStatus
        basket.concerns.forEach((concern) => {
          const { reason, status, instrumentType } = concern;
          // Check for PRIMARY concerns
          if (instrumentType === "PRIMARY") {
            if (status === "REJECTED" && !rejectedReason) {
              rejectedReason = reason; // Take the first REJECTED reason
            } else if (status === "APPROVED" && !approvedReason) {
              approvedReason = reason; // Take the first APPROVED reason
            }
          }

          // Only proceed if there is a reason
          if (reason) {
            // Add `reason` to each instrument
            const instrumentsWithReason = concern.instruments.map(
              (instrument) => ({
                ...instrument,
                reason, // Add the `reason` field to each instrument
                status, // Add the `status` field to each instruments
              })
            );

            // Add to historyInstruments
            historyInstruments = historyInstruments.concat(
              instrumentsWithReason
            );

            // Check if instrument is PRIMARY
            if (concern.instrumentType === "PRIMARY") {
              primaryInstruments = primaryInstruments.concat(
                instrumentsWithReason
              );
            }

            // Check for latest APPROVED concerns
            if (concern.status === "APPROVED") {
              if (
                !latestInstruments.length ||
                new Date(concern.time) >
                  new Date(latestInstruments[0]?.time || 0)
              ) {
                latestInstruments = instrumentsWithReason;
              }
            }
          }

          // Check for PENDING concerns
          if (concern.status === "") {
            pendingInstruments = pendingInstruments.concat(concern.instruments);
          }

          if (concern.instrumentType === "PRIMARY") {
            if (concern.status === "APPROVED") {
              isApproved = true;
            } else if (concern.status === "") {
              isPending = true;
              // isApprovalRequired = true; // Set true if any raHeadStatus is empty
            } else if (concern.status === "REJECTED") {
              isRejected = true;
            }
          }
        });

        // Check if any instrument has empty raHeadStatus
        isApprovalPending = basket.concerns.some(
          (instrument) => instrument.status === ""
        );

        // Set the approval pending state
        setIsApprovalPending(isApprovalPending);

        // Setting the basket status based on the instruments
        if (isRejected) {
          setStatus("REJECTED");
          setRejected(true);
          setStatusUpdated(false);
        } else if (isPending) {
          setStatus("PENDING");
          setRejected(false);
        } else if (isApproved) {
          setStatus("APPROVED");
          setRejected(false);
          setStatusUpdated(false);
        }

        // Save categorized data
        //  setLatestInstruments(latestInstruments); // Update with latest APPROVED instruments
        setPrimaryInstruments(primaryInstruments); // Update with all PRIMARY instruments
        setHistoryInstruments(historyInstruments); // Update with all instruments
        setPendingInstruments(pendingInstruments); // Update with PENDING instruments

        setRejectedReason(rejectedReason);
        setApprovedReason(approvedReason);
      })
      .catch((error) => console.log(error));
  }, [id, token, updateToggle]);

  useEffect(() => {
    const fetchLatestInstruments = () => {
      dispatch(fetchLatestBasketList(id, token))
        .then((res) => {
          if (res.status === "success") {
            setLatestInstruments(res.data.basketList[0].concerns);
          }
        })
        .catch((error) => {
          console.log(error, "error fetchLatestInstruments");
        });
    };
    fetchLatestInstruments();
  }, [id, token, dispatch, updateToggle]);

  // Function must be defined before the useEffect
  const calculateFundREquired = (instrumentListData) => {
    const qty = instrumentListData?.quantity;
    const cmp = instrumentListData?.creationPrice;
    const fundRequired = cmp * qty;

    return fundRequired;
  };

  useEffect(() => {
    if (latestInstruments.length > 0) {
      const total = latestInstruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );
      setTotalFundRequired(Math.ceil(total));
    } else if (primaryInstruments.length > 0) {
      const total = primaryInstruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );

      setTotalFundRequired(Math.ceil(total));
    } else {
      const total = pendingInstruments.reduce(
        (acc, instrument) => acc + calculateFundREquired(instrument),
        0
      );

      setTotalFundRequired(Math.ceil(total));
    }
  }, [data]);

  useEffect(() => {
    dispatch(fetchSymbols(token));
  }, []);

  if (!data) {
    return (
      <Box>
        <Navbar />
        <Text>Loading....</Text>
      </Box>
    );
  }

  const itemsPerPage = 10;
  const instruments = Object.values(data.concerns);
  const totalPages = Math.ceil(instruments.length / itemsPerPage);

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };
  // Pagination helpers
  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  const currentInstruments = instruments.slice(startIndex, endIndex);

  const handleReject = () => {
    setShowRejectModal(true);
  };

  const handleCloseRejectModal = () => {
    setShowRejectModal(false);
    setresearchHeadMessage("");
  };

  // Handle Approve Click (New)
  const handleApprove = () => {
    setShowApproveModal(true);
  };

  // Handle Approve Modal Close (New)
  const handleCloseApproveModal = () => {
    setShowApproveModal(false);
    setresearchHeadMessage("");
  };

  const handleConfirmReject = () => {
    if (researchHeadMessage !== "") {
      let decision = "REJECTED";
      setIsLoading(true)
      dispatch(makeBasketDecision(decision, id, token, researchHeadMessage))
        .then((res) => {
          if (res.status === "success") {
            // setStatusUpdated(false)
            setIsLoading(false)
            toast({
              title: "Basket data has been rejected.",
              position: "bottom",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
            setUpdateToggle(!updateToggle);
          } else {
            toast({
              title: res.message,
              position: "bottom",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
          setUpdateToggle(!updateToggle);
        })
        .catch((error) => {
          setIsLoading(false)
          toast({
            title: "An error occurred.",
            position: "bottom",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          console.log(error);
        });
      setShowRejectModal(false);
      setresearchHeadMessage("");
    }
  };

  // Handle Confirm Approve (New)
  const handleConfirmApprove = () => {
    if (researchHeadMessage !== "") {
      let decision = "APPROVED";
      setIsLoading(true)
      dispatch(makeBasketDecision(decision, id, token, researchHeadMessage))
        .then((res) => {
          if (res.status === "success") {
            setIsLoading(false)
            toast({
              title: "Basket has been approved.",
              position: "bottom",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            setUpdateToggle(!updateToggle);
          } else {
            toast({
              title: res.message,
              position: "bottom",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
          setUpdateToggle(!updateToggle);
        })
        .catch((error) => {
          setIsLoading(false)
          toast({
            title: "An error occurred.",
            position: "bottom",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          console.log(error);
        });
      setShowApproveModal(false);
      setresearchHeadMessage(""); // Reset the message
    }
  };

  const calculateQuantile = (instrumentListData) => {
    const qty = instrumentListData.quantity;
    const stopLoss = instrumentListData.stopLoss;
    const takeProfit = instrumentListData.takeProfit;
    const cmp = instrumentListData.creationPrice;
    const quantile = (cmp - stopLoss) / (takeProfit - stopLoss);
    // Convert quantile to percentage
    const quantilePercentage = quantile * 100;

    let Result = Math.floor(quantilePercentage);

    return `${Result}%`;
  };

  // const calculateFundREquired = (instrumentListData) => {
  //   const qty = instrumentListData.quantity;
  //   const cmp = instrumentListData.creationPrice;
  //   const fundRequired = cmp * qty;
  //   return fundRequired;
  // };

  const InstrumentRow = ({ instrument }) => {
    const quantileValue = parseFloat(calculateQuantile(instrument));

    let badgeText;
    let badgeColor;

    if (quantileValue > 100) {
      badgeText = "Book Profit";
      badgeColor = "green";
    } else if (quantileValue > 0 && quantileValue <= 100) {
      badgeText = "Intrade";
      badgeColor = "green";
    } else {
      badgeText = "Stop Loss";
      badgeColor = "red";
    }

    return <Badge colorScheme={badgeColor}>{badgeText}</Badge>;
  };

  const handleSymbolName = (symbol) => {
    if (symbol !== "" && symbol !== null) {
      let filterSymbolName = Symbols.filter((ele) => ele.instrument == symbol);
      let result = filterSymbolName[0];

      if (result !== undefined) {
        return result.name;
      }
    }

    return symbol; // Return an empty string if the symbol is not found or invalid
  };

  const handleUpsidePotential = (instrumentListData) => {
    let cmp = Number(instrumentListData.creationPrice);
    let takeProfit = Number(instrumentListData.takeProfit);

    let upsidePotential = ((takeProfit - cmp) / cmp) * 100;

    let upsidePotentialPercentage = Math.floor(upsidePotential);

    if (upsidePotentialPercentage < 0) {
      return `Target Achieved`;
    }
    return `${upsidePotentialPercentage}%`;
  };

  const rationalLines =
    data && data.rationale
      ? data.rationale
          .split("\n")
          .map((line) => line.trim())
          .filter((line) => line.length > 0)
      : []; // Default to an empty array if data.rationale is undefined

  const handleBackClick = () => {
    navigate("/rahead/dashboard");
  };

  // Filter symbols based on the search term
  // const filteredSymbols = Symbols.filter((item) =>
  //   item.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // Filters for tables

  const newInstruments = instruments.filter(
    (instrument) =>
      instrument.instrumentType === "Secondary" &&
      instrument.raHeadStatus === ""
  );

  // Step 1: Create a count of instruments
  const instrumentCount = instruments.reduce((acc, instrument) => {
    acc[instrument.instrument] = (acc[instrument.instrument] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Filter out instruments based on your criteria
  const secondaryInstruments = instruments
    .filter((instrument, index, self) => {
      // Ensure uniqueness by checking if it's the first occurrence
      const isFirstOccurrence =
        index === self.findIndex((i) => i.instrument === instrument.instrument);

      // Get all instruments with the same ID
      const sameInstruments = self.filter(
        (i) => i.instrument === instrument.instrument
      );

      // Check for the presence of APPROVED and REJECTED statuses
      const hasApproved = sameInstruments.some(
        (i) => i.raHeadStatus === "APPROVED"
      );
      const hasRejected = sameInstruments.some(
        (i) => i.raHeadStatus === "REJECTED"
      );

      // Include the instrument if:
      // 1. It is APPROVED and there are no other APPROVED instruments
      // 2. It is the first occurrence of APPROVED when both statuses exist
      return (
        (instrument.raHeadStatus === "APPROVED" &&
          hasApproved &&
          hasRejected &&
          isFirstOccurrence) ||
        (instrument.raHeadStatus === "APPROVED" &&
          !hasRejected &&
          instrumentCount[instrument.instrument] === 1) ||
        (instrument.raHeadStatus === "REJECTED" &&
          !hasApproved &&
          isFirstOccurrence)
      );
    })
    .reverse();

  let newInstrumentsData = secondaryInstruments.filter(
    (instrument) => instrument.raHeadStatus === "APPROVED"
  );

  // const primaryInstruments = instruments.filter(
  //   (instrument) => instrument.instrumentType === "Primary"
  // );

  let basketHistory = true;
  if (historyInstruments.length == primaryInstruments.length) {
    basketHistory = false;
  }

  // const currentNewInstruments = newInstruments.slice(startIndex, endIndex);
  // const currentSecondaryInstruments = secondaryInstruments.slice(
  //   startIndex,
  //   endIndex
  // );
  // const currentPrimaryInstruments =
  // primaryInstruments.length > 0
  //   ? primaryInstruments.slice(startIndex, endIndex)
  //   : pendingInstruments.slice(startIndex, endIndex);

  // <==========================================History PAgination==============================>
  // const itemsPerPage = 10;
  // Calculate total pages
  const totalHistoryPages = Math.ceil(historyInstruments.length / itemsPerPage);

  // Calculate start and end index for current page
  const startHistoryIndex = (currentHistoryPage - 1) * itemsPerPage;
  const endHistoryIndex = startHistoryIndex + itemsPerPage;

  // Slice data for current page
  const currentHistoryData = historyInstruments.slice(
    startHistoryIndex,
    endHistoryIndex
  );

  // Handle page change
  const handleHistoryPageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalHistoryPages) {
      setCurrentHistoryPage(newPage);
    }
  };

  // <=================================== Latest Instruments PAgination =================>
  const totalLatestInstrumentPages = Math.ceil(
    latestInstruments.length / itemsPerPage
  );
  // Calculate start and end index for current page
  const startLatestIndex = (latestInstrumentPage - 1) * itemsPerPage;
  const endLatestIndex = startLatestIndex + itemsPerPage;

  // Slice data from current Page

  const currentLatestData = latestInstruments.slice(
    startLatestIndex,
    endLatestIndex
  );

  //Handle Page chanfe
  const handleLatestInstrumentPageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalLatestInstrumentPages) {
      setLatestInstrumentPage(newPage);
    }
  };

  // <======================== Primary Instruments PAgination ============================>
  const totalPrimaryInstrumentPages = Math.ceil(
    primaryInstruments.length / itemsPerPage
  );

  // Calculate start and end index for current page
  const startPrimaryIndex = (currentPrimaryPage - 1) * itemsPerPage;
  const endPrimaryIndex = startLatestIndex + itemsPerPage;

  // Slice data from current Page

  const currentPrimaryData = primaryInstruments.slice(
    startPrimaryIndex,
    endPrimaryIndex
  );

  //Handle Page chanfe
  const handlePrimaryInstrumentPageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPrimaryInstrumentPages) {
      setCurrentPrimaryPage(newPage);
    }
  };

  function formatDate(dateString) {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options).replace(",", "");
  }

  return (
    <Box>
      <Navbar />

      <Flex align="center" justify="flex-start" mt={2} ml={4}>
        <IconButton
          icon={<ArrowBackIcon boxSize={8} />}
          aria-label="Go back"
          onClick={handleBackClick}
          size="lg"
          isRound
          bgColor="#244c9c"
          color="white"
          _hover={{
            transform: "scale(1.2)",
            boxShadow: "0 8px 15px rgba(0, 0, 0, 0.3)",
            bgColor: "#1a3a6b", // Darker shade for hover
          }}
          transition="all 0.3s ease"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.2)"
          _active={{
            transform: "scale(0.95)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          }}
        />
      </Flex>

      <Flex
        direction={{ base: "column", md: "column", lg: "row" }}
        mt="10"
        m={{ base: "2", md: "5" }}
        gap={"10px"}
      >
        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mb={{ base: "5", md: "0" }}
          overflowX="auto" // Ensure horizontal scrolling on small screens
        >
          <Tabs onChange={(index) => setActiveTab(index)} variant="enclosed">
            <TabList>
              <Tab fontWeight="bold">Compositions</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <>
                  {/* Table 1: New Instrument List */}
                  {pendingInstruments.length > 0 ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        Edit Instrument List
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {pendingInstruments.map((instrument, index) => (
                            <Tr
                              key={index}
                              // bg={instrument.orderType === "Entry" ? "green.200" : "red.200"}
                            >
                              <Td>{startIndex + index + 1}</Td>
                              <Td>{handleSymbolName(instrument.instrument)}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.creationPrice}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Table 2: Secondary Instrument List */}
                  {latestInstruments.length > 0 && basketHistory ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mt="10"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        New Basket
                      </Text>
                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                            <Th>Creation Date</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentLatestData.map((instrument, index) => (
                            <Tr key={index}>
                              <Td>{startLatestIndex + index + 1}</Td>
                              <Td>{handleSymbolName(instrument.instrument)}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.creationPrice}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                              <Td whiteSpace="nowrap">
                                {formatDate(instrument.createdAt)}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>

                      {/* Pagination Controls */}
                      {totalLatestInstrumentPages > 1 && (
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          mt="6"
                        >
                          <Button
                            onClick={() =>
                              handleLatestInstrumentPageChange(
                                latestInstrumentPage - 1
                              )
                            }
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<FaArrowLeft />}
                            isDisabled={latestInstrumentPage === 1}
                            bg={
                              latestInstrumentPage === 1
                                ? "gray.300"
                                : "blue.500"
                            }
                            _hover={{
                              bg:
                                latestInstrumentPage === 1
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Previous
                          </Button>

                          <Text fontWeight="bold" color="#244c9c">
                            Page {latestInstrumentPage} of{" "}
                            {totalLatestInstrumentPages}
                          </Text>

                          <Button
                            onClick={() =>
                              handleLatestInstrumentPageChange(
                                latestInstrumentPage + 1
                              )
                            }
                            size="sm"
                            colorScheme="blue"
                            rightIcon={<FaArrowRight />}
                            isDisabled={
                              latestInstrumentPage ===
                              totalLatestInstrumentPages
                            }
                            bg={
                              latestInstrumentPage ===
                              totalLatestInstrumentPages
                                ? "gray.300"
                                : "blue.500"
                            }
                            _hover={{
                              bg:
                                latestInstrumentPage ===
                                totalLatestInstrumentPages
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Next
                          </Button>
                        </Flex>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Table 2: Basket History Instrument List */}
                  {basketHistory && historyInstruments.length > 0 ? (
                    <Box>
                      <Text
                        fontSize="2xl"
                        mt="10"
                        mb="6"
                        fontWeight="bold"
                        color="#244c9c"
                      >
                        Basket History
                      </Text>
                      <Table size="md" width="100%">
  <Thead>
    <Tr>
      <Th>#</Th>
      <Th>Script Name</Th>
      <Th>Quantity</Th>
      <Th>Stop Loss</Th>
      <Th>CMP</Th>
      <Th>Take Profit</Th>
      <Th>Order Type</Th>
      <Th>RaHead Approval</Th>
      <Th width="60%">Reason</Th> {/* Increased width */}
    </Tr>
  </Thead>
  <Tbody>
    {currentHistoryData.reverse().map((instrument, index) => (
      <Tr key={index}>
        <Td>{startHistoryIndex + index + 1}</Td>
        <Td>{handleSymbolName(instrument.instrument)}</Td>
        <Td>{instrument.quantity}</Td>
        <Td>{instrument.stopLoss}</Td>
        <Td>{instrument.creationPrice}</Td>
        <Td>{instrument.takeProfit}</Td>
        <Td
          fontWeight="bold"
          color={
            instrument.orderType === "ENTRY" ? "green.200" : "red.200"
          }
        >
          {instrument.orderType}
        </Td>
        <Td
          fontWeight="bold"
          color={
            instrument.status === "APPROVED" ? "green.200" : "red.200"
          }
        >
          {instrument.status}
        </Td>
        <Td
          wordBreak="break-word"
          whiteSpace="nowrap"
          overflow="auto"
          maxWidth="60%"
          padding="4"
          backgroundColor="gray.50"
        >
          {instrument.reason === "" ? (
            <Text color="gray.500" fontStyle="italic">
              Pending
            </Text>
          ) : (
            <Text fontSize="sm" color="blackAlpha.800">
              {instrument.reason}
            </Text>
          )}
        </Td>
      </Tr>
    ))}
  </Tbody>
</Table>


                      {/* Pagination Controls */}
                      {totalHistoryPages > 1 && (
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          mt="6"
                        >
                          <Button
                            onClick={() =>
                              handleHistoryPageChange(currentHistoryPage - 1)
                            }
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<FaArrowLeft />}
                            isDisabled={currentHistoryPage === 1}
                            bg={
                              currentHistoryPage === 1 ? "gray.300" : "blue.500"
                            }
                            _hover={{
                              bg:
                                currentHistoryPage === 1
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Previous
                          </Button>

                          <Text fontWeight="bold" color="#244c9c">
                            Page {currentHistoryPage} of {totalHistoryPages}
                          </Text>

                          <Button
                            onClick={() =>
                              handleHistoryPageChange(currentHistoryPage + 1)
                            }
                            size="sm"
                            colorScheme="blue"
                            rightIcon={<FaArrowRight />}
                            isDisabled={
                              currentHistoryPage === totalHistoryPages
                            }
                            bg={
                              currentHistoryPage === totalHistoryPages
                                ? "gray.300"
                                : "blue.500"
                            }
                            _hover={{
                              bg:
                                currentHistoryPage === totalHistoryPages
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Next
                          </Button>
                        </Flex>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                  {/* Table 3: Primary Instrument List */}
                  {currentPrimaryData.length > 0 ? (
                    <Box>
                      {(primaryInstruments.length > 0 && basketHistory) ||
                      (pendingInstruments.length > 0 &&
                        primaryInstruments.length > 0) ? (
                        <Text
                          fontSize="2xl"
                          mt="10"
                          mb="6"
                          fontWeight="bold"
                          color="#244c9c"
                        >
                          Primary Instrument List
                        </Text>
                      ) : (
                        ""
                      )}

                      <Table size="md">
                        <Thead>
                          <Tr>
                            <Th>#</Th>
                            <Th>Script Name</Th>
                            <Th>Quantity</Th>
                            <Th>Stop Loss</Th>
                            <Th>CMP</Th>
                            <Th>Take Profit</Th>
                            <Th>Order Type</Th>
                            <Th>Reason</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {currentPrimaryData.map((instrument, index) => (
                            <Tr key={index}>
                              <Td>{startPrimaryIndex + index + 1}</Td>
                              {/* <Td>{handleSymbolName(instrument.instrument)}</Td> */}
                              <Td>{instrument.instrument}</Td>
                              <Td>{instrument.quantity}</Td>
                              <Td>{instrument.stopLoss}</Td>
                              <Td>{instrument.creationPrice}</Td>
                              <Td>{instrument.takeProfit}</Td>
                              <Td
                                fontWeight="bold"
                                color={
                                  instrument.orderType === "ENTRY"
                                    ? "green.200"
                                    : "red.200"
                                }
                              >
                                {instrument.orderType}
                              </Td>
                              <Td
          wordBreak="break-word"
          whiteSpace="nowrap"
          overflow="auto"
          maxWidth="70%"
          padding="4"
          backgroundColor="gray.50"
        >
          {instrument.reason === "" ? (
            <Text color="gray.500" fontStyle="italic">
              Pending
            </Text>
          ) : (
            <Text fontSize="sm" color="blackAlpha.800">
              {instrument.reason}
            </Text>
          )}
        </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                      {/* Pagination Controls */}
                      {totalPrimaryInstrumentPages > 1 && (
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          mt="6"
                        >
                          <Button
                            onClick={() =>
                              handlePrimaryInstrumentPageChange(
                                currentPrimaryPage - 1
                              )
                            }
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<FaArrowLeft />}
                            isDisabled={currentPrimaryPage === 1}
                            bg={
                              currentPrimaryPage === 1 ? "gray.300" : "blue.500"
                            }
                            _hover={{
                              bg:
                                currentPrimaryPage === 1
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Previous
                          </Button>

                          <Text fontWeight="bold" color="#244c9c">
                            Page {currentPrimaryPage} of{" "}
                            {totalPrimaryInstrumentPages}
                          </Text>

                          <Button
                            onClick={() =>
                              handlePrimaryInstrumentPageChange(
                                currentPrimaryPage + 1
                              )
                            }
                            size="sm"
                            colorScheme="blue"
                            rightIcon={<FaArrowRight />}
                            isDisabled={
                              currentPrimaryPage === totalPrimaryInstrumentPages
                            }
                            bg={
                              currentPrimaryPage === totalPrimaryInstrumentPages
                                ? "gray.300"
                                : "blue.500"
                            }
                            _hover={{
                              bg:
                                currentPrimaryPage ===
                                totalPrimaryInstrumentPages
                                  ? "gray.300"
                                  : "blue.400",
                            }}
                          >
                            Next
                          </Button>
                        </Flex>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* Pagination Controls */}
                  {/* {totalPages > 1 && (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      mt="6"
                    >
                      <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        size="sm"
                        colorScheme="blue"
                        leftIcon={<FaArrowLeft />}
                        isDisabled={currentPage === 1}
                        bg={currentPage === 1 ? "gray.300" : "blue.500"}
                        _hover={{
                          bg: currentPage === 1 ? "gray.300" : "blue.400",
                        }}
                      >
                        Previous
                      </Button>

                      <Text fontWeight="bold" color="#244c9c">
                        Page {currentPage} of {totalPages}
                      </Text>

                      <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        size="sm"
                        colorScheme="blue"
                        rightIcon={<FaArrowRight />}
                        isDisabled={currentPage === totalPages}
                        bg={
                          currentPage === totalPages ? "gray.300" : "blue.500"
                        }
                        _hover={{
                          bg:
                            currentPage === totalPages
                              ? "gray.300"
                              : "blue.400",
                        }}
                      >
                        Next
                      </Button>
                    </Flex>
                  )} */}
                </>

                {isApprovalPending ? (
                  <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    marginTop={"5"}
                  >
                    {}
                    <Button colorScheme="green" onClick={handleApprove}>
                      Approve
                    </Button>
                    <Button colorScheme="red" onClick={handleReject}>
                      Reject
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
              </TabPanel>
              <TabPanel>
                <Text>Client data will be displayed here.</Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box
          bg="white"
          w={{ base: "100%", md: "100%", lg: "50%" }}
          p="5"
          borderRadius="md"
          boxShadow="md"
          mt={{ base: "5", md: "0" }}
        >
          <Text fontSize="xl" fontWeight="bold" mb="4" color="blue.500">
            Basket Info: {data.title}
          </Text>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <strong>Basket Name:</strong>
                </Td>
                <Td>{data.title}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Description:</strong>
                </Td>
                <Td fontFamily={"helvetica"}>{data.description}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Basket Rational:</strong>
                </Td>
                <Td>
                  {rationalLines.map((line, index) => (
                    <Box key={index} display="flex" mb={4}>
                      {/* <Text as="span" mr={2} fontSize="md" color="gray.700"> */}
                      <Heading as="h5" size="sm" mr={2}>
                        •
                      </Heading>
                      {/* </Text> */}
                      <Text fontSize="sm" color="gray.700">
                        {line}
                      </Text>
                    </Box>
                  ))}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Expiry Date:</strong>
                </Td>
                <Td>{data.expiryTime}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Creation Date:</strong>
                </Td>
                <Td>{data.creationTime}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong> Created By:</strong>
                </Td>
                <Td fontFamily={"helvetica"}>{data.raName}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Exchange Type:</strong>
                </Td>
                <Td fontFamily={"helvetica"}>{data.exchangeType}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Minimum Fund Required:</strong>
                </Td>
                <Td fontFamily={"helvetica"}> {totalFundRequired}</Td>
              </Tr>
              <Tr>
                <Td>
                  <strong>Research Head Approval:</strong>
                </Td>
                <Td>
                  {status === "PENDING" && (
                    <Text
                      bg="gray.200"
                      p="1"
                      px="3"
                      width={{ base: "90%", md: "40%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      Pending
                    </Text>
                  )}
                  {status === "APPROVED" && (
                    <Text
                      bg="green.200"
                      p="1"
                      px="3"
                      width={{ base: "50%", md: "30%", lg: "50%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      Yes
                    </Text>
                  )}
                  {status === "REJECTED" && (
                    <Text
                      bg="red.200"
                      p="1"
                      px="3"
                      width={{ base: "50%", md: "30%", lg: "30%" }}
                      textAlign={"center"}
                      borderRadius="md"
                    >
                      No
                    </Text>
                  )}
                </Td>
              </Tr>
              {rejected ? (
                <Tr>
                  <Td>
                    <strong>Rejected Reason:</strong>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      {rejectedReason !== ""
                        ? rejectedReason
                        : "Reason Is Not Mentioned"}
                    </Text>
                  </Td>
                </Tr>
              ) : (
                ""
              )}

              {/* {rejected ? (
                <Tr>
                  <Td>
                    <strong>Rejection Reason:</strong>
                  </Td>
                  <Td>{data.rejectedReason}</Td>
                </Tr>
              ) : (
                ""
              )} */}
            </Tbody>
          </Table>
        </Box>
      </Flex>

      {/* Rejection Reason Modal */}
      <Modal isOpen={showRejectModal} onClose={handleCloseRejectModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provide Reason for Rejection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Enter reason for rejection..."
              rows={4}
              value={researchHeadMessage}
              onChange={(e) => setresearchHeadMessage(e.target.value)}
              width="100%"
              resize="none"
              border="1px solid #5274ac"
              _focus={{
                borderColor: "#5274ac",
                boxShadow: "0 0 0 1px #5274ac",
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCloseRejectModal}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirmReject}
              disabled={!researchHeadMessage}
              isLoading={isLoading}
    loadingText='Rejecting'


            >
              Confirm Reject
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* // Approval Reason Modal (New Modal for Approval) */}
      <Modal isOpen={showApproveModal} onClose={handleCloseApproveModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provide Reason for Approval</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Enter reason for approval..."
              rows={4}
              value={researchHeadMessage}
              onChange={(e) => setresearchHeadMessage(e.target.value)}
              width="100%"
              resize="none"
              border="1px solid #5274ac"
              _focus={{
                borderColor: "#5274ac",
                boxShadow: "0 0 0 1px #5274ac",
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleCloseApproveModal}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirmApprove}
              disabled={!researchHeadMessage}
              isLoading={isLoading}
    loadingText='Approving'

            >
              Confirm Approve
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
