import React from 'react';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
import { EditIcon, DeleteIcon, ArrowForwardIcon } from '@chakra-ui/icons';

export default function PositionedMenu({ onAddClient, dealerId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();



  return (
    <>
      {/* IconButton for the menu */}
      <IconButton
        icon={<BsThreeDots />}
        aria-label="Options"
        onClick={isOpen ? onClose : onOpen}
        variant="outline"
        borderRadius="md"
      />

      {/* Menu */}
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton
          as="div"
          display="inline-block"
          onClick={(e) => e.stopPropagation()} // Prevents menu auto-closing on click
        ></MenuButton>
        <MenuList minWidth="200px" fontSize="lg"> {/* Increased size */}
          {/* Add Client */}
          <MenuItem
            icon={<EditIcon />}
            onClick={() => {
              onAddClient(dealerId);
              onClose();
            }}
          >
            Add Client
          </MenuItem>

          {/* Transfer Clients */}
          <MenuItem
            icon={<ArrowForwardIcon />}
            onClick={() => {
              alert(`Transfer Clients to ${dealerId}`);
              onClose();
            }}
          >
            Transfer Clients
          </MenuItem>

          {/* Divider */}
          <MenuDivider />

          {/* Delete Dealer */}
          <MenuItem
            icon={<DeleteIcon />}
            color="red.500"
            onClick={() => {
              alert(`Delete Dealer ${dealerId}`);
              onClose();
            }}
          >
            Delete Dealer
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
