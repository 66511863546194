import { USER_LOADING } from "../actionTypes";
import axios from "axios";

let URL=process.env.REACT_APP_URL
let NewURL = process.env.REACT_APP_NewURL
// let V3_URL = process.env.REACT_APP_VTHREE
let V3_URL = process.env.REACT_APP_VTHREE_AWS


export const managerToken = (data) => (dispatch) => {
 
  dispatch({ type: USER_LOADING });
  return axios.post(
    `${V3_URL}centrum-galaxc/user/v3/auth/genrate-otp/manager?userId=${data.userId}&role=${data.userRole}&password=${data.password}`
  );
};


export const dealerToken = (data) => (dispatch) => {

    dispatch({ type: USER_LOADING });
    return axios.post(
      `${V3_URL}centrum-galaxc/user/v3/auth/genrate-otp/manager?userId=${data.userId}&role=dealer&password=${data.password}`
    );
  };
  
// Action for OTP verification
export const otpVarificationManager = (otp, authToken) => (dispatch) => {
  dispatch({ type: USER_LOADING }); // Dispatch loading state
// console.log(authToken,"otpVarificationManager authToken")
  return axios.post(
    // `${NewURL}web-app/manager/verify-otp?otp=${data.otp}&managerRole=${data.mangerRole}`, 
    `${V3_URL}centrum-galaxc/user/v3/auth/verify-otp/manager?otp=${otp}`,
    {}, // Pass an empty object for the body
    {
      headers: {
        Authorization: `Bearer ${authToken}`, // Pass Bearer token for authentication
      },
    }
  );
};



export const otpVarificationDealer = (otp, authToken) => (dispatch) => {
  dispatch({ type: USER_LOADING }); // Dispatch loading state

  // Make API call to verify the OTP
  return axios.post(
    `${V3_URL}centrum-galaxc/user/v3/auth/verify-otp/manager?otp=${otp}`, 
    {}, // Pass OTP in the request body
    {
      headers: {
        Authorization: `Bearer ${authToken}`, // Pass Bearer token for authentication
        "Content-Type": "application/json",
      },
    }
  );
};


