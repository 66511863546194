import React, { useState, useRef, useMemo } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";

const ScriptDropdown = React.memo(({ symbols, onSelect }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const listRef = useRef(null); // Reference for the list container

  const filteredSymbols = useMemo(() => {
    if (!searchTerm) return symbols;

    const searchTermLower = searchTerm.toLowerCase();
    let results = symbols.filter((item) =>
      item.instrument.toLowerCase().includes(searchTermLower)
    );

    if (results.length === 0) {
      results = symbols.filter((item) =>
        item.name.toLowerCase().includes(searchTermLower)
      );
    }

    return results;
  }, [symbols, searchTerm]);

  const handleSearchInputChange = (value) => {
    setSearchTerm(value);
  };

  const handleDropdownToggle = () => {
    setShowOptions((prev) => !prev);
  };

  const handleSymbolSelect = (item) => {
    setSelectedSymbol(item);
    setShowOptions(false);
    onSelect(item);
  };

  const handleMouseScroll = (event) => {
    const listElement = listRef.current;
    if (listElement) {
      event.preventDefault(); // Prevent default scroll behavior
      listElement.scrollTop += event.deltaY; // Adjust the scroll position
    }
  };

  return (
    <Box position="relative">
      <InputGroup>
        <Input
          value={selectedSymbol ? selectedSymbol.name : ""}
          placeholder="Select Script"
          variant="filled"
          bg="gray.100"
          _focus={{ bg: "white", borderColor: "gray.300" }}
          readOnly
          cursor="pointer"
          onClick={handleDropdownToggle}
        />
        <InputRightElement width="2.5rem">
          <IconButton
            aria-label="Dropdown icon"
            icon={<MdArrowDropDown />}
            variant="ghost"
            onClick={handleDropdownToggle}
          />
        </InputRightElement>
      </InputGroup>

      {showOptions && (
        <Box
          position="absolute"
          top="100%"
          left={0}
          right={0}
          bg="white"
          boxShadow="lg"
          zIndex={10}
          maxHeight="250px"
          overflow="hidden"
          borderRadius="md"
          mt={1}
          p={2}
          onWheel={handleMouseScroll} // Attach the scroll handler
          ref={listRef}
          sx={{
            scrollbarWidth: "thin",
            scrollbarColor: "gray.400 gray.100",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray.400",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "gray.500",
            },
          }}
        >
          <InputGroup mb={2}>
            <Input
              value={searchTerm}
              onChange={(e) => handleSearchInputChange(e.target.value)}
              placeholder="Search Scripts"
              bg="gray.50"
              border="1px"
              borderColor="gray.300"
              _focus={{ bg: "white", borderColor: "blue.500" }}
            />
            <InputRightElement width="2.5rem">
              <IconButton
                aria-label="Clear search"
                icon={<SearchIcon color="gray.500" />}
                variant="ghost"
                onClick={() => setSearchTerm("")}
              />
            </InputRightElement>
          </InputGroup>

          {filteredSymbols.length ? (
            <List spacing={1}>
              {filteredSymbols.map((item) => (
                <ListItem
                  key={item.symbol}
                  onClick={() => handleSymbolSelect(item)}
                  cursor="pointer"
                  _hover={{ background: "gray.100" }}
                  px={4}
                  py={2}
                  borderRadius="md"
                  bg="gray.50"
                  mb={1}
                >
                  {item.name}
                </ListItem>
              ))}
            </List>
          ) : (
            <Text px={4} py={2} color="gray.500">
              No options found
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
});

export default ScriptDropdown;
