import React, { useEffect } from "react";
import Navbar from "../../Components/Dealer/Navbar";
import NoData from "../../Images/NoData.avif"
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  Text,
  SimpleGrid,
  Image,
  Badge,
  Flex,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { fetchBasket, fetchBasketInfo, getSuccessAction } from "../../Redux/basketReducer/action";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchSymbols } from "../../Redux/symbolReducer/action";

export default function DealerDashboard() {
  let token = Cookies.get("login_token_dealer");
  let data = useSelector((store) => store.basketReducer);

  const toast = useToast();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSymbols(token));
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/dealer");
      return;
    }

//     dispatch(fetchBasket(token))
//       .then((res) => {

// // console.log(res,"fetch basket dealers")
//         if (res.data.detail === "Token has expired") {
//           Cookies.set("login_token_dealer", "");
//           Cookies.set("username_dealer", "");
//           navigate("/dealer");
//         }

//         if(res.data.status==="success"){
//           dispatch(getSuccessAction(res.data.data.basketList.reverse()));
//           // setFilterData(res.data.data.basketList);
//         }
//         // if(res.data.status==="success"){
//         //   dispatch(getSuccessAction(res.data.data.basketList.reverse()));
//         //   setFilterData(res.data.data.basketList);
//         // }
//         if (
//           res.data.message == "You do not Have permission to access the data"
//         ) {
//           Cookies.set("login_token_dealer", ``);
//           Cookies.set("username_dealer", "");

//           // navigate("/dealer");
//         } 
//         // else {
//         //   let approvedBaskets = res.data.response.data.filter(
//         //     (ele) => ele.rahStatus === "APPROVED"
//         //   );

//         //   dispatch(getSuccessAction(approvedBaskets.reverse()));
//         // }
//       })
//       .catch((error) => {
//       console.log(error,"Dealer fetch basket error")
//         if(error.response.data.detail!=="Basket does not exist"){
//           toast({
//             title: `${error.message} error`,
//             position: "bottom",
//             status: "error",
//             duration: 2000,
//             isClosable: true,
//           })
        
//         }
//       });


// Fetch basket list
dispatch(fetchBasket(token))
.then((res) => {
  if (res.data.detail === "Token has expired") {
    Cookies.set("login_token_dealer", "");
    Cookies.set("username_dealer", "");
    navigate("/dealer");
  }

  if (res.data.status === "success") {
    const basketList = res.data.data.basketList.reverse();

    // Fetch basket info
    fetchBasketInfo(token)
      .then((infoRes) => {
        if (infoRes.data.status === "success") {
          const basketInfoList = infoRes.data.data.basketList;

          // Merge basket list with basket info based on title
          const mergedData = basketList.map((basket) => {
            const matchingInfo = basketInfoList.find(
              (info) => info.title === basket.title
            );
            return matchingInfo ? { ...basket, ...matchingInfo } : basket;
          });


       
          // setFilterData(mergedData.reverse());
          dispatch(getSuccessAction(mergedData));
        }
      })
      .catch((infoError) => {
        console.error("Error fetching basket info:", infoError);
      });
  }

  if (res.data.message === "You do not Have permission to access the data") {
    Cookies.set("login_token_dealer", "");
    Cookies.set("username_dealer", "");
  }
})
.catch((error) => {
  console.error(error, "Dealer fetch basket error");

  if (error.response?.data?.detail !== "Basket does not exist") {
    toast({
      title: `${error.message} error`,
      position: "bottom",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  }
});
  }, [dispatch, token, toast, navigate]);

  const getStatusStyles = (status) => {
    switch (status) {
      case "APPROVED":
        return {
          borderColor: "green.500",
          boxShadow: "0 0 10px 2px green",
        };
      case "REJECTED":
        return {
          borderColor: "red.500",
          boxShadow: "0 0 10px 2px red",
        };
      case "PENDING":
      default:
        return {
          borderColor: "gray.500",
          boxShadow: "0 0 10px 2px gray",
        };
    }
  };

  const truncateHeading = (description, length = 20) => {
    if (description.length <= length) {
      return description;
    }
    return `${description.substring(0, length)}...`;
  };

  const truncateDescription = (description, length = 40) => {
    if (description.length <= length) {
      return description;
    }
    return `${description.substring(0, length)}...`;
  };

 
  return (
    <Box>
      <Navbar />

      <Tabs
        position="relative"
        variant="unstyled"
        mt="10px"
        borderBottomWidth="1px"
        borderBottomColor="gray.200"
      >
        <TabList display="flex">
          <Tab
            as={Link}
            to="/dealer/dashboard"
            fontWeight="bold"
            color={location.pathname === "/dealer/dashboard" ? "#244c9c" : "gray.500"}
            borderBottom={location.pathname === "/dealer/dashboard" && "2px solid"}
            borderColor={location.pathname === "/dealer/dashboard" && "#244c9c"}
            pb={2}
            _hover={{ color: "#244c9c" }}
            _focus={{ outline: "none" }}
          >
            Basket List
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box p={5}>
              {data.baskets.length > 0 ? (
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                  {data.baskets.map((basket) => (
                    <Link key={basket.ID} to={`/dealer/basket-details/${basket.ID}`}>
                   <Card
  {...getStatusStyles(basket.rahStatus)}
  borderRadius="lg"
  overflow="hidden"
  transition="transform 0.2s"
  _hover={{ transform: "scale(1.05)" }}
>
  <CardHeader p={4} bg="gray.50"  borderRadius="lg" boxShadow="lg">
    <Flex align="center" direction="column" gap={3}>
      <Flex align="center" justify="space-between" width="100%">
        <Image
          src={basket.icon}
          alt={basket.title}
          boxSize="50px"
          mr={4}
          borderRadius="full"
          border="2px solid"
          borderColor="gray.200"
        />
        <Box>
          <Heading size="md" fontWeight="bold" color="blue.600">{truncateHeading(basket.title)}</Heading>
          <Badge
            colorScheme={
              basket.rahStatus === "APPROVED"
                ? "green"
                : basket.rahStatus === "REJECTED"
                ? "red"
                : "gray"
            }
            fontSize="sm"
          >
            {basket.rahStatus}
          </Badge>
        </Box>
      </Flex>

      {/* Additional Details */}
      <Box width="100%" bg="gray.100" p={3} borderRadius="md" boxShadow="md" mt={2}>
        <Flex justify="space-between" fontSize="sm" color="gray.700">
          <Box>
            <Text fontWeight="bold" color="blue.500">Total Returns</Text>
            <Text>{basket.totalReturns || "N/A"}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold" color="blue.500">Total % Returns</Text>
            <Text>{basket.totalPercentageReturns || "N/A"}</Text>
          </Box>
        </Flex>
        <Flex justify="space-between" fontSize="sm" color="gray.700" mt={2}>
          <Box>
            <Text fontWeight="bold" color="blue.500">Investment Amount</Text>
            <Text>{basket.investmentAmount || "N/A"}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold" color="blue.500">Current Amount</Text>
            <Text>{basket.currentAmount || "N/A"}</Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </CardHeader>

  <Divider />

  <CardBody p={4}>
    <Stack spacing={3}>
      <Box>
        <Text fontWeight="bold" fontSize="lg">
          Description
        </Text>
        <Text pt="2" fontSize="sm">
          {truncateDescription(basket.description)}
        </Text>
      </Box>

      <Box>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="lg">
            Clients Subscribed
          </Text>
          <Text pt="2" fontSize="sm">
            {basket.clientsSubscribed || "N/A"}
          </Text>
        </Flex>
      </Box>

      <Box>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="lg">
            Creation Date
          </Text>
          <Text pt="2" fontSize="sm">
            {basket.creationTime}
          </Text>
        </Flex>
      </Box>

      <Box>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="lg">
            Expiry Date
          </Text>
          <Text pt="2" fontSize="sm">
            {basket.expiryTime}
          </Text>
        </Flex>
      </Box>

      <Box>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="lg">
            Exchange Type
          </Text>
          <Text pt="2" fontSize="sm">
            {basket.exchangeType}
          </Text>
        </Flex>
      </Box>
    </Stack>
  </CardBody>
</Card>


                    </Link>
                  ))}
                </SimpleGrid>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100%"
                >
                  <Image
                    src={NoData}
                    alt="No Data"
                    boxSize={{ base: "90%", md: "70%", lg: "50%" }}
                    objectFit="contain"
                    borderRadius="lg"
                    shadow="lg"
                  />
                </Box>
              )}
            </Box>
          </TabPanel>
          <TabPanel>
            <Box p={5}>
              <Heading size="md" mb={4}>
                Create a New Basket
              </Heading>
              {/* Add your form or content for creating a new basket here */}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
