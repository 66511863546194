import logo from './logo.svg';
import './App.css';
import AllRoutes from './Routes/AllRoutes';
import { Heading } from '@chakra-ui/react';
import AddDealerAndDealerList from './Pages/Admin/AddDealerAndDealerList';
import { Button } from '@mui/material';
import TableDealerList from './Components/Admin/TableDealerList/TableDealerList';
import AddClientToTheDealer from './Pages/Admin/AddClientToTheDealer';

function App() {

  return (
    <div className="App">
      
    
      <AllRoutes/>
 {/* <AddDealerAndDealerList/> */}
 {/* <AddClientToTheDealer/> */}
    </div>
  );
}

export default App;
