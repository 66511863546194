import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Box,
  Text,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { FaUserPlus } from "react-icons/fa";

const AddClientModal = ({ open, onClose, clients, onAddClient }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter clients with null, "", or undefined dealerID
  const filteredClients = clients.filter(
    (client) =>
      !client.dealerID || client.dealerID === "" || client.dealerID === null
  );

  // Apply search term filter
  const displayedClients = filteredClients.filter(
    (client) =>
      client?.centrumID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client?.username?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const borderColor = useColorModeValue("gray.300", "gray.600");
  const headerBgColor = useColorModeValue("#9ec0f8", "#9ec0f8");
  const headerTextColor = useColorModeValue("white", "gray.900");

  return (
    <Modal isOpen={open} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="lg" boxShadow="xl" p={4}>
        <ModalHeader
          color={"#244c9c"}
          fontWeight="bold"
          borderRadius="md"
          textAlign="left"
        >
          Add Client
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {/* Search Input */}
          <Input
            placeholder="Search by client name or Centrum ID..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mb={4}
            focusBorderColor="#244c9c"
            borderRadius="lg"
          />

          {/* Table */}
          <Box
            border="1px solid"
            borderColor={borderColor}
            borderRadius="lg"
            overflow="auto"
            maxH="400px"
          >
            <TableContainer>
              <Table size="sm" variant="simple">
                <Thead bg={headerBgColor}>
                  <Tr>
                    <Th
                      textTransform="capitalize"
                      color={headerTextColor}
                      textAlign="center"
                    >
                      Centrum ID
                    </Th>
                    <Th
                      textTransform="capitalize"
                      color={headerTextColor}
                      textAlign="center"
                    >
                      Client Name
                    </Th>
                    <Th
                      textTransform="capitalize"
                      color={headerTextColor}
                      textAlign="center"
                    >
                      Mobile Number
                    </Th>
                    <Th
                      textTransform="capitalize"
                      color={headerTextColor}
                      textAlign="center"
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {displayedClients.length > 0 ? (
                    displayedClients.map((client) => (
                      <Tr key={client.ID}>
                        <Td textAlign="center">{client.centrumID}</Td>
                        <Td textAlign="center">{client.username}</Td>
                        <Td textAlign="center">{client.number}</Td>
                        <Td textAlign="center">
                          <Button
                            size="sm"
                            colorScheme="blue"
                            variant='outline'
                            leftIcon={<Icon as={FaUserPlus} />}
                            onClick={() => onAddClient(client.ID)}
                            borderRadius="md"
                          >
                            Add
                          </Button>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={4} textAlign="center">
                        <Text color="gray.500" fontStyle="italic">
                          No clients available to add.
                        </Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </ModalBody>

      
      </ModalContent>
    </Modal>
  );
};

AddClientModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      emailId: PropTypes.string.isRequired,
      dealerID: PropTypes.string,
      ID: PropTypes.string.isRequired,
    })
  ).isRequired,
  onAddClient: PropTypes.func.isRequired,
};

export default AddClientModal;
